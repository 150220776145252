// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#social-links-container {
    background-color: #fefcfd;
    padding: 2% 0;
    
}

#social-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 20%;
    margin: 0 40%;
}
.social-col:nth-child(1) {
    grid-column: span 2;
}

#social-links-header {
    font-weight: 900;
    width: 80%;
    margin: 0 10% 5%;
    text-align: center;
}

#footer-facebook-icon {
    width: 20%;
    margin: 0 0 0 75%;
}

#footer-instagram-icon {
    width: 20%;
    margin: 0 0 0 4%;
}

@media (max-width: 1199px) and (min-width: 992px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #social-links {
        width: 60%;
        margin: 0 20%;
    }
}

@media (max-width: 361px) {
    #social-links {
        width: 70%;
        margin: 0 15%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/SocialLinks/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,UAAU;IACV,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;AACJ","sourcesContent":["#social-links-container {\n    background-color: #fefcfd;\n    padding: 2% 0;\n    \n}\n\n#social-links {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    width: 20%;\n    margin: 0 40%;\n}\n.social-col:nth-child(1) {\n    grid-column: span 2;\n}\n\n#social-links-header {\n    font-weight: 900;\n    width: 80%;\n    margin: 0 10% 5%;\n    text-align: center;\n}\n\n#footer-facebook-icon {\n    width: 20%;\n    margin: 0 0 0 75%;\n}\n\n#footer-instagram-icon {\n    width: 20%;\n    margin: 0 0 0 4%;\n}\n\n@media (max-width: 1199px) and (min-width: 992px) {\n    #social-links {\n        width: 30%;\n        margin: 0 35%;\n    }\n}\n\n@media (max-width: 991px) and (min-width: 801px) {\n    #social-links {\n        width: 30%;\n        margin: 0 35%;\n    }\n}\n\n@media (max-width: 800px) and (min-width: 768px) {\n    #social-links {\n        width: 30%;\n        margin: 0 35%;\n    }\n}\n\n@media (max-width: 767px) and (min-width: 362px) {\n    #social-links {\n        width: 60%;\n        margin: 0 20%;\n    }\n}\n\n@media (max-width: 361px) {\n    #social-links {\n        width: 70%;\n        margin: 0 15%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
