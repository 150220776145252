// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#reviews-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#reviews-headers-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 20px;
    width: 96%;
    margin: 2% 2%;
}

.reviews-headers-col:nth-child(1) {
    grid-column: 1 / span 2;
}

.reviews-headers-col:nth-child(2) {
    grid-column: 11 / span 2;
}

#reviews-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

.reviews-text {
    width: 80%;
    margin: 0 10%;
    font-size: 18px;
    text-align: center;
}

#reviews {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 2% 3%;
}

.review-card {
    border: solid 1px #d2caca;
    border-radius: 5px;
    padding: 5%;
    transition: transform .5s;
}

.review-card-text, .review-card-date {
    color: black;
}

#write-review-btn {
    background-color: #f11515 !important;
    color: white !important; 
    font-size: 18px!important
}

#write-icon {
    margin-left: 2%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reviews/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,aAAa;IACb,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,2BAA2B;IAC3B,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;IACvB;AACJ;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#reviews-container {\n    background-color: #fefcfd;\n    width: 60%;\n    margin: 0 20%;\n    border: solid 1px #fefcfd;\n}\n\n#reviews-headers-container {\n    display: grid;\n    grid-template-columns: repeat(12, 1fr);\n    row-gap: 20px;\n    width: 96%;\n    margin: 2% 2%;\n}\n\n.reviews-headers-col:nth-child(1) {\n    grid-column: 1 / span 2;\n}\n\n.reviews-headers-col:nth-child(2) {\n    grid-column: 11 / span 2;\n}\n\n#reviews-header {\n    padding: 2%;\n    text-decoration: underline;\n    text-underline-offset: 20px;\n    text-decoration-color: #f11515;\n}\n\n.reviews-text {\n    width: 80%;\n    margin: 0 10%;\n    font-size: 18px;\n    text-align: center;\n}\n\n#reviews {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    column-gap: 20px;\n    row-gap: 20px;\n    padding: 2% 3%;\n}\n\n.review-card {\n    border: solid 1px #d2caca;\n    border-radius: 5px;\n    padding: 5%;\n    transition: transform .5s;\n}\n\n.review-card-text, .review-card-date {\n    color: black;\n}\n\n#write-review-btn {\n    background-color: #f11515 !important;\n    color: white !important; \n    font-size: 18px!important\n}\n\n#write-icon {\n    margin-left: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
