export const zuseSlides = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/new-main-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/screenprinting-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/embroidery-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/fulfillment-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ecommerce-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/graphic-design-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/print-on-demand-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Zuse-Sewing.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/dye-sub-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/dtg-dtf-banner.png"
];

export const zuseSlidesMobile = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/new-main-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/screenprinting-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/embroidery-mobile-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/fulfillment-mobile-banner.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ecommerce-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/graphic-design-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/print-on-demand-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Zuse-Sewing-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/dye-sub-banner-mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/dtg-dtf-banner-mobile.png"
];

export const ACPSlides = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Main.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Screenprinting.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Embroidery.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Fulfillment.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Ecommerce.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Graphic-Design.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-On-Demand.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Sewing.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Dye-Sub.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-DTG-DTF.png"
];

export const ACPSlidesMobile = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Main-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Screenprinting-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Embroidery-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Fulfillment-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Ecommerce-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Graphic-Design-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-On-Demand-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Sewing-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-Dye-Sub-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/ACP-DTG-DTF-Mobile.png"
];

export const unionSlides = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Main.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-ScreenPrinting.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Embroidery.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Fulfillment.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Ecommerce.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Graphic-Design.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-On-Demand.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Sewing.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Dye-Sub.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-DTG-DTF.png"
];

export const unionSlidesMobile = [
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Main-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-ScreenPrinting-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Embroidery-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Fulfillment-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Ecommerce-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Graphic-Design-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-On-Demand-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Sewing-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-Dye-Sub-Mobile.png",
    "https://zuse-inc-bucket.s3.amazonaws.com/banners/Union-DTG-DTF-Mobile.png"
];