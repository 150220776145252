// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#write-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#write-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

.write-text {
    width: 80%;
    margin: 0 10%;
    font-size: 18px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Reviews/Write/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,2BAA2B;IAC3B,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["#write-container {\n    background-color: #fefcfd;\n    width: 60%;\n    margin: 0 20%;\n    border: solid 1px #fefcfd;\n}\n\n#write-header {\n    padding: 2%;\n    text-decoration: underline;\n    text-underline-offset: 20px;\n    text-decoration-color: #f11515;\n}\n\n.write-text {\n    width: 80%;\n    margin: 0 10%;\n    font-size: 18px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
