import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const CatalogSanMar = (props) => {
    return (
        <div>
            <h1>CatalogSanMar</h1>
        </div>
    );
};

export default CatalogSanMar;